import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useEffect, useState } from 'react';
import validator from 'validator';
import serviceUsersService from '../../services/ScaleoAdminApiServices/ServiceUsersService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { CUSTOMER_STATUS } from '../../enums/customer-status.enum';
import {
  Customer,
  InviteOrEditServiceUserDialogProps,
  ServiceUser,
  UserCustomer,
  ValidationErrors,
} from './ServiceUsersService.types';
import { Message } from 'primereact/message';
import { CustomAutoComplete } from '../_shared/CustomComponents';

const INIT_FORM_STATE: ServiceUser = {
  customers: [],
  email: '',
  firstName: '',
  id: '',
  lastName: '',
};

const customerStatusTranslated = [
  { label: 'Zawsze', value: CUSTOMER_STATUS.ACTIVE },
  { label: 'Brak', value: CUSTOMER_STATUS.INACTIVE },
  { label: 'Ograniczony czasowo', value: CUSTOMER_STATUS.LIMITED },
];

const sortInactiveCustomers = (customers: UserCustomer[]) => {
  return customers.sort((a: UserCustomer, b: UserCustomer) => {
    return a.companyName.localeCompare(b.companyName);
  });
};

const getCustomerCreatedAt = (customerToChange: UserCustomer, allCustomers: Customer[]): string => {
  const matchingCustomer = allCustomers.find((customer: Customer) => customer.id === customerToChange.id);

  return matchingCustomer ? matchingCustomer.createdAt : '';
};

const getInactiveCustomers = (userCustomers: UserCustomer[], allCustomers: Customer[]) => {
  const filterCustomers = allCustomers?.filter((customer: Customer) => {
    return !userCustomers?.some((userCustomer: UserCustomer) => {
      return userCustomer?.id === customer?.id;
    });
  });

  return sortInactiveCustomers(
    filterCustomers?.map((customer: Customer, index: number) => {
      return {
        label: customer.companyName,
        companyName: customer.companyName,
        name: customer.companyName,
        id: customer.id,
        index,
        startAt: null,
        expiryAt: null,
        status: CUSTOMER_STATUS.INACTIVE,
        locationsNumber: customer.locationsNumber,
        createdAt: customer.createdAt,
      };
    }),
  );
};

const mapCustomer = (customer: UserCustomer) => {
  return {
    companyName: customer.companyName,
    id: customer.id,
    status: customer.status,
    startAt: customer?.startAt && customer.status === CUSTOMER_STATUS.LIMITED ? new Date(customer?.startAt) : null,
    expiryAt: customer?.expiryAt && customer.status === CUSTOMER_STATUS.LIMITED ? new Date(customer?.expiryAt) : null,
  };
};

const InviteOrEditServiceUserDialog = ({
  user,
  customers,
  hideDialog,
  fetchComponentData,
}: InviteOrEditServiceUserDialogProps) => {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [userCustomers, setUserCustomers] = useState<UserCustomer[]>([]);
  const [inactiveCustomers, setInactiveCustomers] = useState<UserCustomer[]>([]);
  const [locationsNumber, setLocationsNumber] = useState<number>(0);

  useEffect(() => {
    setInitFormValues({ ...INIT_FORM_STATE, ...user });
    setUserCustomers(user?.customers);
    setInactiveCustomers(getInactiveCustomers(user?.customers, customers));

    if (user?.customers) {
      const currentLocationsNumber = user.customers
        .map((customer) => parseInt(customer.locationsNumber))
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0);

      setLocationsNumber(currentLocationsNumber);
    }
  }, [user]);

  const validateMethod = (data: ServiceUser) => {
    const errors: ValidationErrors = {};

    if (!data.firstName) {
      errors.firstName = 'Podaj imie';
    }
    if (!data.lastName) {
      errors.lastName = 'Podaj nazwisko';
    }
    if (!data.email) {
      errors.email = 'Podaj adres e-mail';
    } else if (!validator.isEmail(data.email)) {
      errors.email = 'Niepoprawny e-mail';
    }

    return errors;
  };

  const handleSubmit = (data: ServiceUser, helpers: any) => {
    if (data?.id) {
      serviceUsersService
        .editServiceUser(
          {
            customers: userCustomers ? userCustomers?.map((customer) => mapCustomer(customer)) : [],
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            type: 'SERVICE_USER',
          },
          data.id,
        )
        .then(() => {
          hideDialog();
          void fetchComponentData();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('email', 'Istnieje już użytkownik z podanym adresem e-mail.');
          }
        })
        .finally(() => helpers.setSubmitting(false));
    } else {
      serviceUsersService
        .inviteServiceUser({
          customers: userCustomers ? userCustomers?.map((customer) => mapCustomer(customer)) : [],
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        })
        .then(() => {
          hideDialog();
          void fetchComponentData();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('email', 'Istnieje już użytkownik z podanym adresem e-mail.');
          }
        })
        .finally(() => helpers.setSubmitting(false));
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const userDialogFooter = (
    <>
      <Button type="reset" label="Anuluj" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button
        type="submit"
        label={formik.values.id ? 'Zapisz' : 'Wyślij zaproszenie'}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  const handleChangeCustomerStatus = (customerToChange: UserCustomer, status: CUSTOMER_STATUS, index: number) => {
    const updatedCustomers = userCustomers.filter((customer) => customer.id !== customerToChange.id);
    if (status === CUSTOMER_STATUS.INACTIVE) {
      setLocationsNumber(locationsNumber - parseInt(customerToChange.locationsNumber));

      setInactiveCustomers(
        sortInactiveCustomers([
          ...inactiveCustomers,
          {
            ...customerToChange,
            status,
            index,
            label: customerToChange.companyName,
            createdAt: getCustomerCreatedAt(customerToChange, customers),
          },
        ]),
      );

      setUserCustomers(updatedCustomers);
    } else {
      setUserCustomers([...updatedCustomers, { ...customerToChange, status }]);
    }
  };

  const handleChangeCustomer = (customerToChange: UserCustomer) => {
    if (!customerToChange) {
      void formik.setFieldValue(`searchValue`, null);
      return;
    }

    if (!customerToChange?.id) {
      void formik.setFieldValue(`searchValue`, customerToChange);
      return;
    }

    if (!userCustomers || !userCustomers.length) {
      setUserCustomers([{ ...customerToChange, status: CUSTOMER_STATUS.ACTIVE }]);
    } else if (!userCustomers.includes(customerToChange)) {
      setLocationsNumber(locationsNumber + parseInt(customerToChange.locationsNumber));

      setUserCustomers([...userCustomers, { ...customerToChange, status: CUSTOMER_STATUS.ACTIVE }]);
    }

    const newInactiveCustomers = inactiveCustomers.filter(
      (element: UserCustomer) => element.index !== customerToChange.index,
    );

    setInactiveCustomers(sortInactiveCustomers(newInactiveCustomers));

    void formik.setFieldValue(`searchValue`, null);
  };

  const handleChangeDate = (index: number, value: Date[]) => {
    const newUserCustomers = [...userCustomers];

    newUserCustomers[index].startAt = value?.[0]?.toString();
    newUserCustomers[index].expiryAt = value?.[1]?.toString();

    setUserCustomers(newUserCustomers);

    void formik.setFieldValue(`customers[${index}].startAt`, value?.[0]?.toString(), true);
    void formik.setFieldValue(`customers[${index}].expiryAt`, value?.[1]?.toString(), true);
  };

  const getDateTemplate = (index: number) => {
    const startAt = formik.values.customers?.[index]?.startAt;
    const expiryAt = formik.values.customers?.[index]?.expiryAt;
    return [...(startAt ? [new Date(startAt)] : []), ...(expiryAt ? [new Date(expiryAt)] : [])];
  };

  const renderHeaders = (
    <div className="p-fluid p-formgrid p-grid" style={{ paddingBottom: '10px' }}>
      <div className="p-col-4">
        <label htmlFor="name">
          <b>Klient</b>
        </label>
      </div>
      <div className="p-col-3">
        <label htmlFor="name">
          <b>Liczba oddziałów</b>
        </label>
      </div>
      <div className="p-col-3" style={{ textAlign: 'center' }}>
        <label htmlFor="actions">
          <b>Dostęp</b>
        </label>
      </div>
      <div className="p-col-3" style={{ textAlign: 'center' }}></div>
    </div>
  );

  const renderRows = (field: UserCustomer, index: number) => {
    return (
      userCustomers?.length && (
        <div
          className="p-fluid p-formgrid p-grid p-ai-center"
          style={{ backgroundColor: index % 2 === 0 ? 'whitesmoke' : '', padding: '2px 0' }}
        >
          <div className="p-col-4">
            <span>{field.companyName}</span>
          </div>
          <div className="p-col-3">
            <span>{field.locationsNumber}</span>
          </div>
          <div className="p-col-3">
            <Dropdown
              id={`customers-${index}-status`}
              value={userCustomers[index]?.status}
              options={customerStatusTranslated}
              onChange={(e) => {
                handleChangeCustomerStatus(userCustomers[index], e.value, index);
              }}
              placeholder="Wybierz"
            />
          </div>
          <div className="p-col-3">
            {userCustomers[index]?.status === CUSTOMER_STATUS.LIMITED && (
              <Calendar
                id={`customers-${index}-dates`}
                value={getDateTemplate(index)}
                onChange={(e) => handleChangeDate(index, e.value as Date[])}
                selectionMode="range"
                dateFormat="dd.mm.y"
                placeholder="Wybierz zakres"
                showTime
                baseZIndex={5000}
              />
            )}
          </div>
        </div>
      )
    );
  };

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={true}
        header="Szczegóły użytkownika serwisowego"
        modal
        className="p-fluid"
        footer={userDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '80vw' }}
      >
        <form>
          <div className="p-col-12">
            <div className="p-field">
              <label htmlFor="email">E-mail</label>
              <InputText id="email" value={formik.values.email} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>
            <div className="p-field">
              <label htmlFor="firstName">Imię</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="firstName" formikInstance={formik} />
            </div>
            <div className="p-field">
              <label htmlFor="lastName">Nazwisko</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="lastName" formikInstance={formik} />
            </div>
            <div className="flex justify-content-center">
              <Message severity="info" text={`Aktualna liczba przypisanych oddziałów: ${locationsNumber}/10`} />
            </div>
            {userCustomers?.length > 0 && (
              <div className="p-field" style={{ margin: '20px 0' }}>
                {renderHeaders}
                {userCustomers.map((field, i) => (
                  <div key={`customer--${i}`}>{renderRows(field, i)}</div>
                ))}
              </div>
            )}
            {inactiveCustomers?.length > 0 && (
              <>
                <label htmlFor="addCustomer">Dodaj klienta</label>
                <CustomAutoComplete
                  id="addCustomer"
                  value={formik.values.searchValue}
                  handler={(e: any) => handleChangeCustomer(e.target?.value)}
                  source={inactiveCustomers}
                  lookupField={'companyName'}
                  inputStyle={undefined}
                  customSearchBranch={undefined}
                  customItemTemplate={undefined}
                  placeholder={'Wybierz'}
                  scrollHeight={undefined}
                  tooltip={undefined}
                  disabled={undefined}
                  forceSelection={undefined}
                  onBlur={undefined}
                />
              </>
            )}
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default InviteOrEditServiceUserDialog;
